import vue from '@/main.js';
import fun from './task_fun';
import common from './task_fun/common';
import beforeOpen from './task_fun/beforeOpen';
import sendTaskFun from './task_fun/sendTaskFun';
import openPublicNotice from './task_fun/openPublicNotice';
import tools from '@/api/tools';
import {PTaskLog, PTaskDetail, UserInfo, UserInfoSimplePmcs} from '@/types';
import types from '@/utils/types';
import store from '@/store'


/**
任务打开方式:
普通
call 调用
create 创建
edit 编辑
appoint_widget 需要调转到指定控件:Object{ widget_id: 控件id, parent_id: 父控件id}
share 分享
system 系统消息
chat 需要直接打开聊天
chat_message_id 需要跳转到指定id
jump 发送后跳转
draft 草稿
select_target 需要选择发送目标
recycle 回收站任务
view 预览
relevancy 加载关联数据
use: 我要使用
hide_fun: 隐藏功能栏
 */

const clearTaskData = ()=>{
  const {commit} = vue.$store;
  // 清空数据
  commit('set_task_chat_message_list', []);
  commit('set_task_unsent_message', false);
  commit('set_task', {});
  commit('set_teach_files', Object.assign(
    {},
    vue.$store.state.teach_files_basic,
  ))
  commit('set_task_config', Object.assign(
    {},
    vue.$store.state.task_config_basic,
    {}
  ));
}
/**
 * 打开消息盒子
 * @param {Object} params
 */
const taskDetail = async (params) => {
  const { taskId, url } = params || {}
  const { commit, state } = vue.$store
  const { task_config } = state
  const {
    create,
    // edit,
    call,
    recycle,
    share,
    relevancy,
    select_target,
    // create_instance,
    view,
  } = task_config
  // const userid = vue.$tools.getUserId()
  console.log({ task_config })
  if (create) {
    const res = {
      task: createTask(),
      taskDeatails: [],
      taskUsers: createTaskUser(),
      readtaskUsers: [],
      message: 'success'
    }
    commit('set_task', res)
    commit('set_task_original', null)
    commit('set_task_config', Object.assign(
      {},
      task_config,
      {
        users: res.taskUsers,
        chat_type: res.task.chatType || 0,
        chat_id:  res.task.chatId || 0,
        task_type: 1
      }
    ))
    return res;
  }
  const data = {
    taskId,
    linkflag: 1,
    seeType: 0 // 0: 正常查看, 1: 分享查看, 2: 回收
  }
  if (recycle) {
    data.seeType = 2
  }
  if (share) {
    data.seeType = 1
  }
  // 调用关联数据
  if (relevancy) {
    data.linkflag = 1
  }
  const res = await vue.$global.doPost({
    url: url || '/projectTask/taskDetail',
    data,
    preventDuplicateClicks: true
  })
  if (res.message === 'success') {
    const { contents } = res
    if (contents) {
      let teach_id = null
      if (contents.task.taskType == 2) {
        teach_id = contents.task.id
        if (!view) {
          contents.taskUsers = createTaskUser()
        }
      }
      if (contents.task.taskType ==3) {
        teach_id = contents.task.teachId;
      }
      if (contents.taskUsers.length == 0 || call) {
        if(view){
          contents.taskUsers = [
            new UserInfo({
              thumbnail: contents.task.createuserthumbnail,
              userName: contents.task.createusername,
              userId: contents.task.userId,
            })
          ]
        }else{
          contents.taskUsers = createTaskUser()
        }
      }
      // 转化对象
      contents.taskDeatails = types.transformObjectForList({
        list: contents.taskDeatails,
        obj: PTaskDetail,
      })
      // 转化关联对象
      if(contents.taskDeatails && contents.taskDeatails.length){
        for(const unit of contents.taskDeatails){
          unit.controlLinkList = types.transformObjectForList({
            list: unit.controlLinkList,
            obj: PTaskDetail
          })
        }
      }
      let deep_contents = contents
      // // 将创建者移到第一位
      let create_user = {
        userId: deep_contents.task.userId,
        thumbnail: deep_contents.task.createuserthumbnail,
        userName: deep_contents.task.createusername
      }
      const index = deep_contents.taskUsers.findIndex(item=> {
        return item.userId == deep_contents.task.userId
      })
      if (index !== -1) {
        create_user = deep_contents.taskUsers[index]
        deep_contents.taskUsers.splice(index,1)
      }
      deep_contents.taskUsers.unshift(create_user)
      deep_contents.task.taskFold = false // 展开折叠控件
      deep_contents.task.clearWidgetTitle = false // 清除控件标题
      deep_contents.task.clearWidgetContent = false // 清除控件内容
      let deep_contents_origin = vue.$tools.deepClone(deep_contents)
      // 调用时 创建任务id设置为自己
      if (call) {
        const chatType = tools.getChatType()
        if (!select_target) {
          console.log(chatType)
          deep_contents.task.chatType = chatType ?? 0
          deep_contents_origin.task.chatType = deep_contents.task.chatType
          // if (Number(chatType) !== 0) {
          const { $route } = vue
          const { params } = $route
          const { id } = params
          if (id) {
            const chatId = tools.atob(id)
            deep_contents.task.chatId = chatId ?? 0
            deep_contents_origin.task.chatId = deep_contents.task.chatId
          }
          // }
        }
        const userid = vue.$tools.getUserId()
        deep_contents.task.userId = userid
        deep_contents_origin.task.userId = userid
        deep_contents.taskUsers = createTaskUser()
        deep_contents.tousers = ''
        deep_contents_origin.taskUsers = createTaskUser()
        deep_contents_origin.tousers = ''
        console.log({deep_contents,deep_contents_origin})
        // deep_contents.task.draft = 0;
        if (deep_contents.task.taskType == 2) {
          deep_contents.task.teachId = deep_contents.task.id
        }
        deep_contents.task.id = undefined // 调用的任务清除任务id
        // 调用移除智慧桌
        deep_contents.taskDeatails.forEach((item) => {
          if (item.type == 11) {
            item.screens = ''
            item.screenList = []
            item.screenstatus = 0
          }
          item.controlLinkList.forEach((unit) => {
            if (unit.type == 11) {
              unit.screens = ''
              unit.screenList = []
              item.screenstatus = 0
            }
          })
        })
        deep_contents.task.createuserthumbnail = undefined
        deep_contents.task.createusername = undefined
      }
      console.log(deep_contents)
      commit('set_task', deep_contents)
      commit('set_task_original', deep_contents_origin)
      // console.log(contents.taskUsers)
      commit('set_task_config', Object.assign(
        {},
        task_config,
        {
          users: call && !view ? createTaskUser() : contents.taskUsers,
          chat_type: deep_contents.task.chatType,
          chat_id:  deep_contents.task.chatId,
          task_type: contents.task.taskType,
          task_id: contents.task.id,
          editable: call? true:beforeOpen.taskEditable({users: contents.taskUsers, tousers: contents.task.tousers}),
          teach: (contents.task.taskType == 2 || contents.task.taskType == 3) ? true : false,  //是否含有教学内容
          price: contents.task.price,
          introduction: contents.task.introduction,
          teach_id: teach_id,
          draft: contents.task.draft == 1,
          select_target: select_target ?? contents.task.taskType == 2 ?? task_config.draft,
          creater_id: beforeOpen.taskCreaterId(), // 创建者id
          color: contents.task.colour,
          from: contents.task.from,
          task_name: contents.task.name || '',
          teachType: contents.task.teachType || '',
        }
      ))
      // console.log(vue.$store.state.task_config)
      beforeOpen.generateTaskConfig()
      beforeOpen.generateTaskDetails()
    }
  }
  return res
}



function createTask(){
  const route = vue.$route;
  let chatId = -1;
  let chatType = -1;
  let task = {
    levelStatus: '',
    processStatus: ''
  }
  if(route.name.indexOf('fun_task_dialog') != -1){
    if(route.params.id){
      // chat_id = route.params.id;
      chatId = vue.$api.task.getRouteParamsId();
      chatType = vue.$tools.getChatType();
      if(chatType === 1){
        task.from = vue.$store.state.dialog_data.chatName;
      }
      if(chatType === 0){
        const user_data = vue.$store.state.user_data;
        task.from = user_data.friendRemark || user_data.userName;
      }
    }
  }
  if(chatId != -1){
    task.chatId = chatId;
  }
  if(chatType != -1){
    task.chatType = chatType;
  }
  return task;
}

function createTaskUser(){
  let taskUsers = [];
  const route = vue.$route;
  const user = vue.$tools.getCookie('user')
  const { task_config } = vue.$store.state
  const { use, call } = task_config
  // 默认选择自己
  taskUsers.push(user)
  // 如果是'我要使用' 则默认只生成自己
  if (use) {
    if(route.name.indexOf('fun_task_dialog') !== -1){
      if(route.params.id){
        // const chatId = vue.$api.task.getRouteParamsId();
        const chatType = vue.$tools.getChatType();
        if(chatType === 0){
          taskUsers.push(vue.$store.state.user_data);
        }
      }
    }
    return taskUsers
  }
  // 如果是单聊则添加对方
  if(route.name.indexOf('fun_task_dialog') !== -1){
    if(route.params.id){
      // const chatId = vue.$api.task.getRouteParamsId();
      const chatType = vue.$tools.getChatType();
      if(chatType === 0){
        taskUsers.push(vue.$store.state.user_data);
      }
    }
  }
  return taskUsers;
}




class Task{
  common = common
  sendTaskFun = sendTaskFun
  clearTaskData = clearTaskData
  taskDetail = taskDetail

  recoverTask = async (params = {})=>{
    const { otask, otask_config, otask_origin } = params ?? {}
    const { commit } = store
    commit('set_task', otask)
    commit('set_task_config', otask_config)
    commit('set_task_original', otask_origin)
    commit('set_switch_task_box', true) //打开
  }

  /**
   * 打开任务
   * @param {*} params
   * @param {*} params.taskId
   * @param {*} params.config
   * @param {*} params.url
   * @param {*} params.currentChatId
   * @param {*} params.callback
   */
  openTask = async (params={}) => {
    vue.$showLoading({text:'加载中'})
    const { taskId, config, url, callback, currentChatId } = params
    const { call } = config
    const ctask_list = store.state.ctask_list
    console.log(ctask_list)
    // 如果不是调用 && 已经是最小化任务 则直接打开最小化的任务
    if (ctask_list && ctask_list.length) {
      const taskListIndex = ctask_list.findIndex((item) => {
        return Number(item.otask.task.id) === Number(taskId)
      })
      if (taskListIndex !== -1 && !call) {
        await this.recoverTask(ctask_list[taskListIndex])
        ctask_list.splice(taskListIndex, 1)
        return
      }
    }

    console.log(params)
    const {commit} = vue.$store
    clearTaskData()
    commit(
      'set_task_config',
      Object.assign(
        {},
        vue.$store.state.task_config,
        config
      )
    )
    const task_detail_res = await taskDetail({
      taskId,
      url
    })
    // console.timeEnd('openTask')
    console.log(vue.$store.state.task_config, config)
    vue.$hideLoading()
    if(task_detail_res.message !== 'success'){
      return
    }
    console.log(task_detail_res)

    // 如果传入了currentChatId 则判断不是当前群聊的话 弹出提示
    if (task_detail_res && task_detail_res.contents && task_detail_res.contents.task && Number(task_detail_res.contents.task.chatType) !== 0) {
      if ((currentChatId ?? '') !== '') {
        if (Number(currentChatId) !== Number(task_detail_res.contents.task.chatId)) {
          if (callback) {
            callback(task_detail_res)
          }
          return
        }
      }
    }
    commit('set_switch_task_box', true) //打开
  }

  /**
   * 获取任务数据 不打开弹层
   * @param {*} params
   */
  getTask = async (params={}) => {
    vue.$showLoading({text:'加载中'});
    const {taskId, config, url} = params;
    const {commit} = vue.$store;
    clearTaskData();
    commit('set_task_config', Object.assign({}, vue.$store.state.task_config, config))
    const task_detail_res = await taskDetail({taskId, url});
    vue.$hideLoading();
    if(task_detail_res.message !== 'success'){
      return false;
    }
    return task_detail_res;
  }
  /**
   * 调用任务
   * @param {*} params
   */
  callTask = async (params) => {
    const {taskId, config} = params || {}
    // this.closeTask(); // 关闭
    this.onlyCloseTask()
    clearTaskData() // 清除数据

    const callConfig = Object.assign(
      {},
      vue.$store.state.task_config_basic,
      {
        call: true,
        relevancy: true,
      },
      config,
    )
    console.log(callConfig)
    this.openTask({
      taskId,
      config: callConfig
    });
  }

  getRelevancy = async (params) => {

    const {id} = params;
    if(id){
      vue.$showLoading({text:'加载中'});
      const res = await vue.$global.doPost({
        url: '/projectTask/getLinks',
        data: {id},
      })
      vue.$hideLoading();
      return res;
    }
  }

  openPublicNotice = async (params) => {
    const { commit, state } = vue.$store
    const { chat_id } = params
    openPublicNotice.clearPublicNotice()
    commit(
      'set_task_config',
      Object.assign(
        {},
        state.task_config,
        {
          public_notice: true,
          chat_id
        }
      )
    )
    vue.$showLoading({ text: '加载中' })
    await openPublicNotice.getChatDetail(chat_id); //获取聊天信息
    await openPublicNotice.getTaskNoticeDetail(chat_id); // 获取公告数据
    vue.$hideLoading()
    // 取消公告已读
    commit(
      'set_message_list_data',
      Object.assign(
      {},
        state.message_list_data,
      {
          chat: {
            readflag: 0,
          }
        }
      )
    )

    vue.$hideLoading()
  }

  onlyCloseTask = () => {
    const { commit } = store
    commit('set_switch_task_box', false)
  }

  closeTask = async (params) => {
    const { title, desc, onSure, onCancel, callback } = params ?? {}
    const { commit, state } = store
    const userid = vue.$tools.getUserId()
    const { task, task_config } = state
    const { open_by_call, modified } = task_config
    const { taskDeatails } = task
    const isTaskDetailsModified = taskDeatails.find((item) => {
      return item.modified
    })
    if (isTaskDetailsModified && task.task.id) {
      vue.$openConfirm({
        title: title ?? `是否关闭任务?`,
        desc: desc ?? '关闭后，已编辑的内容无法恢复',
        onSure: async () => {
          let ids = '';
          taskDeatails.forEach( item => {
            if(item.islock && item.userlock == userid){
              ids += ids == '' ? item.id : `,${item.id}`
            }
            if(item.controlLinkList && item.controlLinkList.length){
              item.controlLinkList.forEach( unit => {
                if(unit.islock && unit.userlock == userid){
                  ids += ids == '' ? unit.id : `,${unit.id}`
                }
              })
            }
          })
          // 清除锁
          if (ids != '') {
            await vue.$http.request('/projectTask/loselock', {ids})
          }
          commit('set_switch_task_box', false)
          vue.$oss.cancelAll() // 取消全部上传

          // 如果通过调用打开 并且未编辑  则重新打开调用
          if (open_by_call && modified == false) {
            this.openTask({
              config: {
                create: true,
                open_with_call_page: true
              }
            })
          }
          if (onSure) {
            onSure()
          }
        },
        onCancel: () => {
          if (onCancel) {
            onCancel
          }
        }
      })
      return
    }
    let ids = ''
    taskDeatails.forEach( (item) => {
      if (item.islock && item.userlock == userid) {
        ids += ids == '' ? item.id : `,${item.id}`
      }
      if (item.controlLinkList && item.controlLinkList.length) {
        item.controlLinkList.forEach((unit) => {
          if (unit.islock && unit.userlock == userid) {
            ids += ids == '' ? unit.id : `,${unit.id}`
          }
        })
      }
    })
    // 清除锁
    if (ids != '') {
      await vue.$http.request('/projectTask/loselock', { ids })
    }
    commit('set_switch_task_box', false)
    vue.$oss.cancelAll() // 取消全部上传
    // 如果通过调用打开 并且未编辑  则重新打开调用
    console.log(open_by_call, modified, task_config)
    if (open_by_call && modified == false) {
      this.openTask({
        config:{
          create: true,
          open_with_call_page: true,
        }
      })
    }
    if (callback) {
      callback()
    }
  }
  /**
   * 设置颜色
   * @param {Object} params {taskId:id, color:色值}
   * @returns
   */
  setColor = async (params) => {
    const {task_config} = vue.$store.state;
    const {draft} = task_config;
    const {taskId, color} = params || {};
    const data = {
      taskId,
      colour: color,
    }
    const res = await vue.$global.doPost({
      url: '/projectTask/setcolour',
      data,
      openNoitce: false, // 错误不提示
    })
    if(res.message === 'success'){
      this.changeMyTaskList({taskId, fieldName:'colour', fieldValue: color});
      this.changeTemplateMyList({taskId, fieldName:'colour', fieldValue: color});
      if(draft){
        vue.$api.editTask.changeListFields({
          taskId,
          fieldName: 'colour',
          fieldValue: color,
          listName: 'draft_list',
        })
      }
      return true;
    }
    return false;
  }

  // 提取
  /**
     * @description: 更改操作组标记
     * @param {*} params {taskId: 任务id, fieldName: 字段名, fieldValue: 字段值}
     * @return {*}
     */
  changeMyTaskList(params){
    const {taskId, fieldName, fieldValue} = params || {};
    let {my_task_list} = vue.$store.state;
    if(!my_task_list || my_task_list.length == 0){
      return;
    }
    let aim = my_task_list.find((item)=>{
      return item.id == taskId;
    } )
    if(aim != undefined){
      aim[fieldName] = fieldValue;
    }
  }
  // 提取
  /**
   * @description: 更改我的模板标记
   * @param {*} params {taskId: 任务id, fieldName: 字段名, fieldValue: 字段值}
   * @return {*}
   */
  changeTemplateMyList(params){
    const {taskId, fieldName, fieldValue} = params || {};
    let {template_my_list} = vue.$store.state.template;
    if(!template_my_list || template_my_list.length == 0){
      return;
    }
    let aim = template_my_list.find((item)=>{
      return item.id == taskId;
    } )
    if(aim != undefined){
      aim[fieldName] = fieldValue;
    }
  }
  /**
   * 设置隐私
   * @param {Object} params {taskId: id, privacy:boolean}
   * @returns
   */
  setPrivacy = async (params)=>{
    const {taskId, privacy} = params || {};
    const data = {
      taskId,
      hide: privacy ? 1 : 0,
    }
    const res = await vue.$global.doPost({
      url: '/projectTask/setHide',
      data,
      notice: '隐私模式设置失败'
    })
    if(res.message === 'success'){
      if(privacy){
        vue.$notice({desc: '已设为仅参与人可见', type: 'success'});
      }else{
        vue.$notice({desc: '已设为全部参与人可见', type: 'success'});
      }
      vue.$api.task.updatePrivacyData({task_id: taskId, privacy});
      common.updateMessageListSpecifiedFields({taskId, fielsd:{hide:privacy}});
      common.updateMyTaskListSpecifiedFields({taskId, fielsd:{hide:privacy}});
      return true;
    }
    return false;
  }
  /**
   * 发送任务
   * @param {*} params
   * @returns
   */
  sendTask = async (params) => {
    vue.$showLoading({text:'发布中'})
    const { commit, state } = vue.$store
    const { task_config } = state
    const { create, edit, call } = task_config
    let res
    console.log(task_config)
    if (create) {
      commit('set_task_config', Object.assign(
        {},
        vue.$store.state.task_config,
        {
          create_draft: false, // 创建时 关闭创建草稿标记
        })
      )
      res = await fun.createTask()
    }
    if (edit) {
      res = await fun.editTask()
    }
    if (call) {
      res = await fun.callTask()
    }
    if (res!== null) {
      sendTaskFun.afterSendTask(res)
    }
    vue.$hideLoading()
    return res
  }

  createTeach = async (params) => {
    vue.$showLoading({text:'发布中'});
    const {config} = params || {};
    const {commit} = vue.$store;
    commit('set_task_config', Object.assign(
      {},
      vue.$store.state.task_config,
      {
        create_teach:true,
        // call: true,
      },
      config
    ));
    const res = await fun.createTeach();

    // sendTaskFun.afterSendTask(res);
    if(res && res.message == 'success'){
      sendTaskFun.afterSendTeach(res);
    }
    vue.$hideLoading();
    return res;
  }

  editTeach = async (params) => {
    vue.$showLoading({text:'发布中'});
    const { config, checkstatus } = params || {};
    const closeTask = params.closeTask ?? true // 是否关闭任务
    const { commit } = vue.$store;
    commit('set_task_config', Object.assign(
      {},
      vue.$store.state.task_config,
      {
        edit_teach:true,
      },
      config
    ));
    const res = await fun.editTeach({
      closeTask,
      checkstatus,
    });
    if(res && res.message === 'success'){
      sendTaskFun.afterSendTeach({
        res,
        closeTask
      });
    }
    vue.$hideLoading();
    return res;
  }

  createDraft = async (params) => {
    vue.$showLoading({text:'保存中'});
    const {config} = params || {};
    const {commit} = vue.$store;
    commit(
      'set_task_config',
      Object.assign(
        {},
        vue.$store.state.task_config,
        // {create_draft: true},
        config
      ))
    const res = await fun.createDraft()
    vue.$hideLoading();
    if(res){
      vue.$notice({desc: '保存成功', type: 'success'});
      sendTaskFun.afterSaveDraft(res);
    }
    return res;
  }
  /**
   * 编辑草稿
   * @param {*} params
   * @returns
   */
  editDraft = async (params) => {
    vue.$showLoading({ text: '保存中' })
    const {config} = params || {}
    const {commit} = vue.$store
    commit(
      'set_task_config',
      Object.assign(
        {},
        vue.$store.state.task_config,
        {edit_draft: true},
        config
      )
    )
    const res = await fun.editDraft()
    vue.$hideLoading()
    if (res) {
      sendTaskFun.afterSaveDraft(res)
      this.closeTask()
      vue.$notice({ desc: '保存成功', type: 'success' })
    }
    return res
  }

  sendDraft = async (params) => {
    vue.$showLoading({text:'发布中'});
    const {config} = params || {};
    const {commit} = vue.$store;
    const task_config = vue.$store.state.task_config;
    commit('set_task_config', Object.assign(
      {},
      vue.$store.state.task_config,
      {
        task_type: 1,
        send_draft: true,
        jump: true,
        call: true,
      },
      config
    ));
    const res = await fun.sendDraft();
    if(res!== null && res !== false){
      sendTaskFun.afterSendTask(res);
    }
    // 恢复task_config
    if(res === false){
      commit('set_task_config', Object.assign(
        {},
        task_config,
      ));
    }
    vue.$hideLoading();
    return res;
  }
  createInstance = async (params) => {
    vue.$showLoading({text:'发布中'});
    const {config} = params || {};
    const {commit, state} = vue.$store;
    const {task_config} = state;
    const {call} = task_config
    commit('set_task_config', Object.assign(
      {},
      vue.$store.state.task_config,
      {
        create_instance: true,
        select_target: task_config.select_target ?? true,
        jump: true,
        call: true,
      },
      config
    ));
    const res = await fun.createInstance();
    if(res!== null && res){
      sendTaskFun.afterSendTask(res);
    }else{
      // 有错误 还原之前的config;
      commit('set_task_config', Object.assign(
        {},
        vue.$store.state.task_config,
        {
          call,
        },
        config
      ));
    }

    vue.$hideLoading();
    return res;
  }
  editInstance = async (params) => {
    vue.$showLoading({text:'发布中'});
    const {config} = params || {};
    const {commit} = vue.$store;
    commit('set_task_config', Object.assign(
      {},
      vue.$store.state.task_config,
      {
        edit_instance: true
      },
      config
    ));
    const res = await fun.editInstance();
    if(res!== null){
      sendTaskFun.afterSendTask(res);
    }
    vue.$hideLoading();
    return res;
  }

  createReminder = async (params) => {
    const res = await fun.createReminder(params);

    common.updateReminderConcrened({taskId: params.taskId, type: 'increase'})
    return res;
  }

  editReminder = async (params) => {
    const res = await fun.editReminder(params);
    common.updateReminderConcrened({taskId: params.taskId, type: 'increase'})
    return res;
  }
  deleteReminder = async (params) => {
    const res = await fun.deleteReminder(params);
    common.updateReminderConcrened({taskId: params.taskId, type: 'decrease'})
    return res;
  }


  sendWidget = async (item, parent) => {
    vue.$showLoading({text:'发布中'});
    const res = await fun.sendWidget(item, parent);

    if(res && res!== null){
      sendTaskFun.afterSendWidget(res, item, parent);
    }
    vue.$hideLoading();
    return res;
  }

  editPublicNotice = async () => {
    const res = await fun.editPublicNotice();
    if(res.message == 'success'){
      sendTaskFun.afterEditPublicNotice(res);
    }
    return res;
  }


  /**
   * 标记控件修改
   * @param {Object} item 控件数据
   */
  signWidgetModified = (item) => {
    const {id} = item;
    if(id){
      vue.$set(item, 'modified', true);
    }
  }

  getLock = async (item) => {
    const {task_config} = vue.$store.state;
    const {call} = task_config;
    if(call){
      return true;
    }
    let userid = vue.$tools.getUserId();

    let {id, updatenum, userlock, islock} = item || {};
    if(id && userlock!=userid){
      let res = await vue.$http.request('/projectTask/getlock', {id, updatenum});
      if(res.message == 'success'){
        vue.$set(item, 'userlock', userid);
        item.userlock = userid;
        vue.$set(item, 'islock', 1);
        item.islock = 1;
        return true;
      }else if(res.message == 'updated'){
        await this.updateControlData(res, item);
        // this.clickMenuOutside();
        return false;
      }else{
        vue.$set(item, 'islock', 1);
        item.islock = 1;
        vue.$notice({desc:res.describe})
        // this.clickMenuOutside();
        return false;
      }
    }else{
      return true;
    }
  }
  removeLock = async (item)=>{
    let {id, updatenum, userlock, islock} = item || {};
    let res = await vue.$http.request('/projectTask/loselock', {ids: id});
    if(res.message == 'success'){
      return true;
    }
    return ;
  }

  /**
   * @description: 更新控件数据
   * @param {*}
   */
  updateControlData = async (res, item) => {
    vue.$openConfirm({
      title:'',
      desc:'控件内容已被 更新',
      sure:'确定',
      cancel:'我再看看',
      onSure:()=>{
        let data = res.contents.PTaskDetail;
        // console.log(data);
        for(let i in data){
          vue.$set(item, i, data[i]);
        }
        vue.$set(item, 'repeal_content', true);//重绘
        if(data.type == 9){
          vue.$nextTick(function(){
            vue.$redrawVueMasonry(); // 重绘
          })
        }
        // 更新超链接
        // if(data.type == 7){
        //   this.$refs.control_hyperlink.updateContent(data.content);
        // }
      },
      // onCancel:function(){},
    })
  }

  /**
   * 获取路由id
   */
  getRouteParamsId(){
    const { params } = vue.$route
    const { id } = params
    return vue.$global.decodeBase64(id);
  }

  /**
   * 用于linkflag == 1 时, 触发已读
   * 仅触发接口, 不做任何处理
   * @param {*} params
   */
  readedTask(params){
    const {taskId} = params;
    if(taskId){
      vue.$global.doPost({
        url: '/projectTask/taskDetail',
        data: {taskId},
        openNoitce: false,
      })
    }

  }

  /**
   * 判断控件是否高亮
   * @param {*} params
   * @returns
   */
  widgetHeightlight(params){
    if(!params){
      return false;
    }
    const {task_config} = vue.$store.state;
    const {task_log:taskLog} = task_config;
    if(!taskLog){
      return false;
    }
    const {details} = taskLog;
    if(!details){
      return false;
    }
    const {controlType, type} = details[0];
    // 创建不高亮
    if(controlType === 101 && type === 1){
      return false;
    }
    const {id} = params;
    const aim = details.find(item=>{
      return item.conId == id;
    })
    if(aim){
      return true;
    }
    const parent_aim = details.find(item=>{
      return item.conIdP && item.conIdP == id;
    })
    if(parent_aim){
      return true;
    }
    return false;
  }

  /**
   * 重置调用页面参数
   */
  resetCallConfig(){
    vue.$store.commit('set_task_call_config', Object.assign(
      {},
      {
        search_type: 'default',
        search_keyword: '',
        switch_custom: false,
        tag_list: [],
        appoint: null,
        search_tags: '',
      }
    ))
  }

  /**
   * 查询任务参与人
   * @param {*} params
   * @returns
   */
  getTaskUsers = async (params={}) => {
    const {task_id:taskId} = params;
    if(!taskId){
      return false;
    }
    const res = await vue.$global.doPost({
      url: '/projectTask/queryTaskUserkList',
      data: { taskId }
    })
    const {message,contents} = res;
    if(message === 'success'){
      if(contents){
        const {taskUsers:list} = contents;
        if(!list){
          return false;
        }
        const task_users_list = Object.assign(
          {},
          {
            task_id: taskId,
            list,
          }
        )
        vue.$store.commit("set_task_users_list", task_users_list)
        return task_users_list;
      }
    }
    return false;



  }



}

const task = new Task();

export default task;



// /**
//  * 检查任务是否有可以投屏的控件
//  * @param {*} list
//  * @returns
//  */
//  function checkWisdom(list){
//   let can_projection = false;
//   if(list && list.length){
//     list.forEach(item=>{
//       if(item.type == 11){
//         if(item.canProjectionScreen() && !item.id){
//           can_projection = true;
//         }else{
//           const {controlLinkList} = item;
//           if(controlLinkList && controlLinkList.length){
//             const has = controlLinkList.find(cell=>{
//               return cell.type == 11 && !cell.id && cell.canProjectionScreen && cell.canProjectionScreen();
//             })
//             if(has){
//               can_projection = true;
//             }
//           }
//         }
//       }else{
//         const {controlLinkList} = item;
//         if(controlLinkList && controlLinkList.length){
//           const has = controlLinkList.find(cell=>{
//             return cell.type == 11 && !cell.id && cell.canProjectionScreen && cell.canProjectionScreen();
//           })
//           if(has){
//             can_projection = true;
//           }
//         }
//       }
//     })
//   }
//   return can_projection;
// }
