<script>
export default {
  props: {
    msgData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    checkClassName () {
      const { checkstatus } = this.msgData
      if (checkstatus === 3) {
        return 'approve'
      }
      if (checkstatus === 4) {
        return 'fail'
      }
      return ''
    },
    showCheckStatusIcon () {
      const { checkstatus } = this.msgData
      if (checkstatus === 3) {
        return true
      }
      if (checkstatus === 4) {
        return true
      }
      return false
    },
    checkText () {
      const { checkstatus } = this.msgData
      if (checkstatus === 3) {
        return '审核通过'
      }
      if (checkstatus === 4) {
        return '审核未通过'
      }
      return '邀请审核'
    }
  },
  methods: {
    touchTask () {
      this.$api.moduleTask.openTask({
        taskId: this.msgData.taskId,
        config: {
          auditMode: true,
          edit: false,
          view: true,
        }
      })
    }
  },
}
</script>

<template>
<div
  class="audit_task bbox_wrapper cp un_sel"
  @click.stop="touchTask"
>
  <div
    class="info_group d-felx align-start justify-space-between"
  >
    <div
      class="name_group"
    >
      <div
        class="title"
      >
        {{ msgData.taskName }}
      </div>
      <div
        class="subtitle"
        v-html="msgData.work"
      >
      </div>
    </div>
    <div
      class="icon flex-shrink-0"
    ></div>
  </div>
  <div
    class="status_wrapper d-flex align-center justify-space-between"
  >
    <div
      class="status_group d-flex align-center"
      :class="[checkClassName]"
    >
      <div
        v-if="showCheckStatusIcon"
        class="icon"
      ></div>
      <div
        class="text"
      >
        {{ checkText }}
      </div>
    </div>
    <div
      class="tag_group"
    >
      任务
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.audit_task {
  width: 350px;
  background: #FFFFFF;
  border-radius: 4px 4px 10px 10px;
  padding: 12px;
  color: #333;
  .info_group {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #f4f4f4;
    .name_group {
      .title {
        @include single_line_intercept;
        font-size: 14px;
        color: #333333;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        margin-bottom: 10px;
      }
      .subtitle {
        @include single_line_intercept;
        font-size: 12px;
        color: #666666;
        line-height: 16px;
        text-align: left;
        font-style: normal;
      }
    }
    .icon {
      width: 50px;
      height: 50px;
      margin-left: 15px;
      background-image: url(~@/assets/images/course_package/invite_audit_task.png);
    }
  }
  .status_wrapper {
    width: 100%;
    padding-top: 20px;
    .status_group {
      font-weight: 400;
      font-size: 12px;
      color: #82929C;
      line-height: 17px;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
      .icon {
        @include background(14px 14px);
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      &.approve {
        .icon {
          background-image: url(~@/assets/images/course_package/select_approve.png);
        }
        .text {
          color: #05AD00;
        }
      }
      &.fail {
        .icon {
          background-image: url(~@/assets/images/course_package/select_fail.png);
        }
        .text {
          color: #D82E0D;
        }
      }
    }
    .tag_group {
      width: 46px;
      border-radius: 4px;
      border: 1px solid $main_blue;
      font-weight: 400;
      font-size: 12px;
      color: $main_blue;
      line-height: 20px;
      font-style: normal;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
</style>
