<script>
import { mapState } from 'vuex'
import oss from '@/api/modules/oss'
import axios from 'axios'

export default {
  computed: {
    ...mapState({
      appUserInfo: state => state.app.userInfo,
    }),
    showMajor () {
      const {
        identity
      } = this
      return identity === '' || identity === 1 || identity === 2
    },
  },
  data () {
    return {
      avatarUrl: require('@/assets/images/login/default_avator.png'),
      BASEURL: process.env.VUE_APP_BASEURL,
      loading: false,
      identityList: [
        {
          name: '教师',
          value: 1,
        },
        {
          name: '学生',
          value: 2,
        },
        {
          name: '企业用户',
          value: 3,
        },
        {
          name: '自由职业者',
          value: 4,
        },
        {
          name: '其他',
          value: 5,
        },
      ],
      identity: '',
      major: '',
      majorList: [
        {
          name: '会展策划与管理（540112）'
        },
        {
          name: '旅游管理（540101）'
        },
        {
          name: '酒店管理与数字化运营（540106）'
        },
        {
          name: '市场营销（530605）'
        },
        {
          name: '国际经济与贸易（530501）'
        },
        {
          name: '文化创意策划（550401）'
        },
        {
          name: '网络与新媒体（360101）'
        },
        {
          name: '视觉传达设计（550102）'
        },
        {
          name: '展示艺术设计（550110）'
        },
        {
          name: '环境艺术设计（550106）'
        },
        {
          name: '数字媒体艺术（550103）'
        },
        {
          name: '数字媒体技术（510204）'
        },
        {
          name: '其他'
        },
      ],
      userCardNo: '',
      department: '',
      userRealName: '',
      ownedEnterprises: '',
      thumbnail: '',
      userName: '',
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const {
        appUserInfo
      } = this
      if (!appUserInfo) {
        return
      }
      const {
        identity,
        major,
        department,
        userRealName,
        ownedEnterprises,
        userCardNo,
      } = appUserInfo
      this.identity = identity
      this.major = major
      this.department = department
      this.userRealName = userRealName
      this.ownedEnterprises = ownedEnterprises
      this.userCardNo = userCardNo
    },
    /**
     * 校验身份证
     * @param id
     * @returns {boolean}
     */
    validateIDCard (id) {
      const idCardRegex = /^(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][0-9])|(3[0-1]))\d{3}[\dXx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(1[0-2]))(([0-2][0-9])|(3[0-1]))\d{3}$)$/
      return idCardRegex.test(id)
    },
    async doSubmit () {
      if (this.loading) {
        return
      }
      const {
        userCardNo,
        identity,
        major,
        department,
        userRealName,
        ownedEnterprises,
        userName,
        thumbnail,
      } = this

      if (identity === '') {
        this.$notice({
          desc: '请选择身份',
          type: 'error'
        })
        return
      } else {
        if (identity === 1 || identity === 2) {
          if (!major) {
            this.$notice({
              desc: '请选择专业',
              type: 'error'
            })
            return
          }
        } else {
          if (department === null || department.trim() === '') {
            this.$notice({
              desc: '请输入部门信息',
              type: 'error'
            })
            return
          }
        }

      }

      if (userName.trim() === '') {
        this.$notice({
          desc: '请输入昵称',
          type: 'error'
        })
        return
      }
      if (userRealName.trim() === '') {
        this.$notice({
          desc: '请输入真实姓名',
          type: 'error'
        })
        return
      }

      if (!this.validateIDCard(userCardNo)) {
        this.$notice({
          desc: '请输入正确格式的身份证号',
          type: 'error'
        })
        return
      }

      if (ownedEnterprises === null ||  ownedEnterprises.trim() === '') {
        this.$notice({
          desc: '请输入单位名称',
          type: 'error'
        })
        return
      }

      const data = {
        identity,
        userRealName,
        userCardNo,
        ownedEnterprises,
        userName,
        thumbnail,
      }
      if (identity === 1 || identity === 2) {
        data.major = major
        data.department = ''
      } else {
        data.major = ''
        data.department = department
      }
      // this.loading = true
      // const res = await this.appUserInfo.realNameAuthentication(data)
      // this.loading = false
      //
      // if (res) {
      //   this.$notice({
      //     desc: '认证成功',
      //     type: 'success'
      //   })
      //   this.$modal.hide('globalAdditionInformation')
      // }

      this.$emit(
        'completeNew',
        data
      )


    },
    doCancel () {
      this.$emit('goBack', 1)
    },
    touchAvatar () {
      this.$refs.avatar.click()
    },
    async picChange () {
      const file = this.$refs.avatar.files[0]

      const res = await this.uploadFile({
        file
      })
      this.thumbnail = res

    },
    async uploadFile (params) {
      // console.log(params)
      // const {
      //   file,
      // } = params
      // if (!file) {
      //   return
      // }
      // const res = await oss.uploadAvatar({
      //   file
      // })
      // return res
      const formData = new FormData()
      formData.append('Filedata', params.file) // 要提交给后台的文件
      // console.log(formData.get('Filedata'));
      // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = { // 添加请求头
        headers: { 'Content-Type': 'multipart/form-data' }
      };
      const res = await axios.post(this.BASEURL + '/project/upLoadPic', formData, config)
        // .then(res => {
        //   // console.log(res, 'sssss');
        //   this.imgUrl = res.contents.picurl.replace(/\|\|/, '')
        //   // console.log(this.imgUrl);
        //   let reg = /(\|\|)(\S*)/
        //   this.imgUrl2 = reg.exec(res.contents.picurl)[2]
        //   // console.log('imgUrl2', this.imgUrl2)
        //   this.$refs.uploadAvatar.clearFiles();
        // })
      let reg = /(\|\|)(\S*)/
      const avatar = reg.exec(res.contents.picurl)[2]
      return avatar
    },
    goToLogin () {
      this.$router.push({
        name: 'login_sign'
      })
    }
  }
}
</script>

<template>
  <div
    v-loading="loading"
    class="v_login_addition_information bbox_wrapper"
  >
    <div
      class="information_wrapper d-flex align-center justify-center"
    >
      <div
        class="avatar_group d-flex align-center flex-column justify-center"
      >
        <div
          class="avatar cp"
          :style="{
            backgroundImage: thumbnail ? `url(${ $avatar_url }${ thumbnail }@!small200)` : `url(${ avatarUrl })`
          }"
          @click.stop="touchAvatar"
        ></div>
        <input
          type="file"
          style="display: none"
          accept="image/*"
          ref="avatar"
          @change="picChange"
        >
        <div
          class="avator_hint"
        >
          上传头像
        </div>
        <input
          v-model="userName"
          type="text"
          class="username"
          placeholder="请输入用户昵称"
        >
      </div>
      <div
        class="info_wrapper"
      >
        <!--身份-->
        <div
          class="info_group"
        >
          <div
            class="key"
          >
            <div
              class="symbol"
            >
              *
            </div>
            <div
              class="text"
            >
              身&nbsp;&nbsp;份:
            </div>
          </div>
          <div
            class="value"
          >
            <el-select
              v-model="identity"
              clearable
              placeholder="请选择身份"
              class="select_group views_active_page_laboratory_index_select_group"
              value="identities"
            >
              <el-option
                v-for="item in identityList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!--真实姓名-->
        <div
          class="info_group"
        >
          <div
            class="key"
          >
            <div
              class="symbol"
            >
              *
            </div>
            <div
              class="text"
            >
              真实姓名:
            </div>
          </div>
          <div
            class="value"
          >
            <input
              v-model="userRealName"
              type="text"
              class="text"
              placeholder="请输入真实姓名"
            >
          </div>
        </div>
        <!--身份证-->
        <div
          class="info_group"
        >
          <div
            class="key"
          >
            <div
              class="symbol"
            >
              *
            </div>
            <div
              class="text"
            >
              身份证号码:
            </div>
          </div>
          <div
            class="value"
          >
            <input
              v-model="userCardNo"
              type="text"
              class="text"
              placeholder="请输入身份证号码"
              maxlength="18"
            >
          </div>
        </div>
        <!--单位名称-->
        <div
          class="info_group"
        >
          <div
            class="key"
          >
            <div
              class="symbol"
            >
              *
            </div>
            <div
              class="text"
            >
              单位名称:
            </div>
          </div>
          <div
            class="value"
          >
            <input
              v-model="ownedEnterprises"
              type="text"
              class="text"
              placeholder="请输入单位名称"
            >
          </div>
        </div>
        <div
          class="info_group"
          v-show="showMajor"
        >
          <div
            class="key"
          >
            <div
              class="symbol"
            >
              *
            </div>
            <div
              class="text"
            >
              专&nbsp;&nbsp;业:
            </div>
          </div>
          <div
            class="value"
          >
            <el-select
              v-model="major"
              clearable
              placeholder="请选择专业"
              class="select_group views_active_page_laboratory_index_select_group"
              value="identities"
            >
              <el-option
                v-for="item in majorList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          v-show="!showMajor"
          class="info_group"
        >
          <div
            class="key"
          >
            <div
              class="symbol"
            >
              *
            </div>
            <div
              class="text"
            >
              部&nbsp;&nbsp;门:
            </div>
          </div>
          <div
            class="value"
          >
            <input
              v-model="department"
              type="text"
              class="text"
              placeholder="请输入部门名称"
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="fun_group d-flex align-center justify-center"
    >
      <div
        class="btn btn_dark"
        @click.stop="doSubmit"
      >
        完成注册
      </div>
      <div
        class="btn btn_light"
        @click.stop="doCancel"
      >
        取消注册
      </div>
    </div>
    <div
      class="return_group d-felx align-center justify-end"
    >
      <div
        class="text"
      >
        已有账号?
      </div>
      <div
        class="btn_login cp"
        @click.stop="goToLogin"
      >
        立即登录
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.v_login_addition_information {
  width: 100%;
  .title_group {
    text-align: center;
    font-size: 18px;
    color: #333333;
    line-height: 70px;
    font-style: normal;
    border-bottom: 2px solid #F0F0F0;
  }
  .information_wrapper {
    width: 100%;
    padding: {
      top: 80px;
    }
    .avatar_group {
      flex: 1;
      .avatar {
        @include background(92px, 92px);
        width: 92px;
        height: 92px;
        background-image: url(~@/assets/images/login/default_avator.png);
      }
      .avator_hint {
        font-size: 18px;
        color: #0054A7;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        padding-top: 10px;
      }
      .username {
        @include placeholder(#D5D9DB);
        color: #333;
        font-size: 18px;
        line-height: 60px;
        text-align: left;
        font-style: normal;
        margin-top: 40px;
        width: 290px;
        border-bottom: 1px solid #D5D9DB;
        outline: none;
        padding: 0 10px;
      }
    }
    .info_wrapper {
      flex: 1;
      .info_group {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        padding-right: 100px;
        .key {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          padding-right: 20px;
          .symbol {
            font-weight: 400;
            font-size: 14px;
            color: #E12F2F;
            line-height: 12px;
            text-align: right;
            font-style: normal;
            margin-right: 5px;
          }
          .text {
            width: 94px;
            height: 21px;
            font-size: 16px;
            color: #0054A7;
            line-height: 21px;
            text-align: justify;
            text-align-last: justify;
            font-style: normal;
          }
        }
        .value {
          width: 100%;
          flex: 1;
          border-bottom: 1px solid #D5D9DB;
          .select_group {
            width: 100%;
            .el-input__inner {
            }
          }
          .text {
            @include placeholder(#ccc);
            line-height: 40px;
            width: 100%;
            padding-left: 15px;
            font-size: 14px;
            color: #333;
            outline: none;
          }
        }
      }
    }
  }
  .fun_group {
    width: 100%;
    padding: {
      top: 50px;
      bottom: 20px;
    }
    .btn {
      width: 200px;
      height: 50px;
      border-radius: 4px;
      line-height: 50px;
      font-size: 18px;
      text-align: center;
      font-style: normal;
      margin: 0 10px;
    }
  }
  .return_group {
    font-size: 18px;
    color: #7E9EBA;
    line-height: 24px;
    text-align: right;
    font-style: normal;
    width: 100%;
    padding-right: 60px;
    .btn_login {
      color: $main_blue;
      text-decoration: underline;
    }
  }
}
</style>
<style lang="scss">
.views_active_page_laboratory_index_select_group {
  .el-input__inner {
    @include placeholder(#ccc);
    border: none!important;
    color: #333;
  }
}
</style>
