// import request from '@/api/request'
const course_package = {
  state: {
    id: undefined, // 当前编辑的id
    data: undefined, // 当前编辑的教学包
    section: undefined, // 当前编辑的章节
    packageList: [], // 当前教学包列表
  },
  mutations: {
    setCoursePackageId (state, data) {
      state.id = data
    },
    setCoursePackageData (state, data) {
      state.data = data
    },
    setCoursePackageSection (state, data) {
      state.section = data
    },
    setCoursePackageList (state, data) {
      state.packageList = data
    },
  },
  actions: {
    /**
     * 获取场景
     * @param context
     * @param params
     * @returns {Promise<*>}
     */
    // async getARSceneList (context, params) {
    //   console.log(context, params)
    //   const res = await request.request(
    //     '/vr/myModelList',
    //     {
    //       pn: params.pn
    //     }
    //   )
    //   return res
    // },
  }
}

export default course_package
